import './App.css';
import image1 from './pics/pic1.png'; // Adjust the file name as necessary
import image2 from './pics/pic2.png';
import image3 from './pics/pic3.png';
import image4 from './pics/4.png';

import image5 from './pics/5.png';
import image6 from './pics/6.png';
import apppic from './pics/apppic.png';
import spanishflag from './pics/spanishflag.jpg';
import englishflag from './pics/englishflag.jpg';
import germanflag from './pics/germanflag.png';

import {auth,provider} from './backend/firebase-config'
import {signInWithPopup} from "firebase/auth"



function App() {

  const handleclick = () => {
    signInWithPopup(auth, provider)
      .then((data) => {
        alert('Waitlist joined!!');
      })
      .catch((error) => {
        console.error("Error during sign in: ", error);
      });
  };



  return (
    <div className="App">
      <header className="App-header">
        <p className='logoname'>talk<span className='aitext'>AI</span>de</p>
      </header>
      <div className="content">
        <h1>Speak Your Language, Be Heard Worldwide</h1>
        <p className="subtitle">AI-Powered Communication: Language is No Barrier to Connect, Communicate, Collaborate</p>
        <button className="join-btn" onClick={handleclick}>Join Waitlist Now</button>
      </div>




      <div className='homepagepics'>

    <div className='leftsidediv'>


    <div class="translation-box">
    <div class="title">Translation</div>
    <div class="language-options">
        <div class="language backgroundcolor">
            <img src={englishflag} alt="English" />
            <span>English</span>
        </div>
        <div class="language backgroundcolor">
            <img src={germanflag} alt="German" />
            <span>German</span>
        </div>
        <div class="language backgroundcolor">
            <img src={spanishflag} alt="Spanish" />
            <span>Spanish</span>
        </div>
        <div class="language  ">
            <span class="age-rating">13+</span>
        </div>
    </div>
</div>

      
    <div class="message-bubble">
  <img src={image6} alt="Profile Picture" class="profile-pic" />
  <div class="message-content">
    <p class="username">Alex</p>
    <p class="message">See you in london man...</p>
  </div>
</div>




    </div>









<div class="apppic-container">
  

<img src={apppic} alt="app ui pic" class='apppic' />
</div>


<div className='rightsidediv'>


<div class="message-bubble rightsidefirstmsgbubble">
  <img src={image4} alt="Profile Picture" class="profile-pic" />
  <div class="message-content">
    <p class="username">Pooja</p>
    <p class="message">Dheli is hottt🥵🥵</p>
  </div>  
</div>


<div class="message-bubble">
       <img src={image5} alt="Profile Picture" class="profile-pic" />
         <div class="message-content">
            <p class="username">Yuzé 手杖</p>
            <p class="message">perfecto amigoo... 😍</p>
         </div>
  </div>




</div>





      </div>




      

      <div className='callchatcontainer'>
      <h1 className='heading'>Call & Chat Translation: Connecting Worlds in Every Conversation</h1>
        <p className='paragraph'>Bringing Everyone Together, No Matter The Language</p>
        <div className="ccimgcontainer">
        <img src={image1} alt="Description of Image 1" className="ccimg1" />
        <img src={image2} alt="Description of Image 2" className="ccimg2" />
      </div>
      

      <div className='lastcontainer'>
      <h1 className='heading'>Synchronized Understanding</h1>
        <p  >AI Lip-sync in Video Calls</p>
        <div className="ccimgcontainer">
        <img src={image3} alt="Description of Image 2"  />
      </div>
      <div className='lastbuttons'>
      <button className="join-btn" onClick={handleclick}>Join Waitlist Now</button>
      <a href="https://www.linkedin.com/groups/12901428/" target="_blank">
        <button className='join-btn'>Linkedin</button>
    </a>
      </div>
      </div>


      </div>
    </div>
  );
}

export default App;
