// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth, GoogleAuthProvider} from "firebase/auth"
const firebaseConfig = {
  apiKey: "AIzaSyChJnw8gNwfeU95lrizvNRp1gTviI49QLo",
  authDomain: "talkaide.firebaseapp.com",
  projectId: "talkaide",
  storageBucket: "talkaide.appspot.com",
  messagingSenderId: "257675676291",
  appId: "1:257675676291:web:fcfbb3822f188b925629b6",
  measurementId: "G-G0M5TYH2YN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth(app)
const provider = new GoogleAuthProvider();


export {auth, provider};